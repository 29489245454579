import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from "gatsby";
import { Container,Row, Col,Card } from 'react-bootstrap';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import algoliasearch from "algoliasearch/lite";
import { navigate } from "@reach/router";
import qs from "qs";
import { isTablet, isMobileOnly, isMobile } from "react-device-detect";
import $ from "jquery";
import {
  InstantSearch,
  connectInfiniteHits,
  connectHits,
  connectStateResults,
  connectStats,
  connectSearchBox,
  RefinementList,
  connectRange,
  connectMenu,
  Configure
} from "react-instantsearch-dom";
import Cookies from 'universal-cookie';

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import MainContent from '@components/main-content/main-content';
import PropertyIntro from '@components/property-intro/property-intro';
//import Properties from '@components/properties/properties';
// import Property from '@components/property/property';
import {
  parseSearchUrl,
  propertyH1
} from "@components/common/property/utils";
import { SEARCH_TYPES } from "@components/common/property/constants";
import { PROPERTIES_PER_PAGE } from "@components/properties/constants";

const mycookies = new Cookies();

// TODO: hard coded / repeated details to be removed/replaced with common variables
// TODO: Indentation of this file need to be corrected

const no_image = "/images/no-team.png"

const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

const searchClient = algoliasearch(
  "T9KB5Q6U02",
  "ca15ee044c5a2240f78ac261653f1aa2"
)
const index_name = "dev_strapi_property_new"

const InfiniteHits = ({
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
  location,
}) => (
  <>
    <Row>
      <Col lg={12} className="text-center">
        {(hits.length > 0 && hasPrevious) && (
          <button
            id="myprevbut"
            onClick={refinePrevious}
            disabled={!hasPrevious}
            className="btn btn-link text-uppercase text-white"
          >
            Show prev
          </button>
        )}
      </Col>
    </Row>
    <Row>
      {hits.map(hit => {
        let uriStr = ""
        let imgURl = no_image
        let imageName = ""
        if (hit.images && "" !== hit.images) {
          imageName = hit.images[0]
        }

        if (hit.bedroom) {
          if (hit.bedroom === 0) uriStr += "studio-"
          else if (hit.Bedrooms === 1) uriStr += `${hit.bedroom}-bedroom-`
          else if (hit.Bedrooms > 1) uriStr += `${hit.bedroom}-bedrooms-`
        }
        if (hit.Building_Type) {
          uriStr += `${hit.Building_Type.toLowerCase()}-`
        }
        uriStr += "for-sale-"
        if (hit.Areaslug) {
          uriStr += `in-${hit.Areaslug}`
        }
        let url = `/property-detail/${hit.slug}`;
        return (
          <Col className="px-md-4" lg={4} md={6} key={hit.objectID}>
          <Link 
              onClick={() => mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 })}
              to={url}
              aria-label="todo"
              state={{ referrer: location.pathname }}
              sx={{
                width: "100%",
                py: [3],
                px: [1, 3],
                willChange: "transform",
                transition: "transform 0.25s ease-out",
                ":focus": {
                  transform: "translateY(-5px) scale(1.01)",
                },
                ":focus .tags": {
                  bg: "#00000038",
                  visibility: "visible",
                },
                ":hover": {
                  transform: "translateY(-5px) scale(1.01)",
                },
                ":hover .tags": {
                  bg: "#00000048",
                  visibility: "visible",
                },
              }} >
          <div className={classNames('property')}>
      <div className="property_image">
        {/* <Card.Img 
          variant="top" src={imageName}
        /> */
        <img 
          src={imageName}
        />
        }
        <div className="property__heart"></div>
      </div>
      <h5>£{hit.price.toLocaleString()} PCM</h5>
      <h6>{hit.display_address}</h6>
      <p>{hit.title}</p>
    </div>
    </Link>
    </Col>
        )
      })}
    </Row>
    <Row>
      <Col lg={12} className="text-center">
        {(hits.length > 0 && hasMore) && (
          <button
            id="mynextbut"
            onClick={refineNext}
            disabled={!hasMore}
            className="btn btn-link results-show-more text-uppercase"
          >
            Show more
          </button>
        )}
      </Col>
    </Row>
  </>
)

export const createURL = (props, state) => {
  // console.log('Inside rentals.js createURL');
  // console.log(state);

  var myarea = state.menu

  const isDefaultRoute = !state.query && !state.sortBy && state.page === 1 && state.query && !myarea["Areaslug"] && state.range && !state.range.Bedrooms && !state.range.price
  if (isDefaultRoute) {
    return ""
  }

  var areaPath = "in-west-yorkshire/"
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var sortPath = ""

  if (state.query) {
    if (state.query) {
      areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    }
  }

  if (state.sortBy) {
    if( state.sortBy === "price_DEV_desc")
      sortPath = "sortby-price-desc/"
    if( state.sortBy === "bedroom_DEV_asc")
      sortPath = "sortby-bedroom-desc/"
  }

  const queryParameters = {}

  if (state.query) {
  //  queryParameters.query = encodeURIComponent(decodeURIComponent(state.query))
  }
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  if (state.range) {
    if (state.range.hasOwnProperty('bedroom') && state.range.bedroom.max === 0) {
      bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
    }
    if (state.range.bedroom) {
      if (state.range.bedroom.min) {
        bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
      }
    }
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath =
          "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
      } else if (state.range.price.max) {
        pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
      } else if (state.range.price.min) {
        pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
      }
    }
  }
  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })
  let myUrl = `${areaPath}${bedroomPath}${pricePath}${queryString}${pagePath}${sortPath}`

  if ("/" === myUrl.substr(myUrl.length - 1))
    myUrl = myUrl.substr(0, myUrl.length - 1)

  // console.log(myUrl);

  return `/property/rentals/${myUrl}`
}
const searchStateToUrl = (props, searchState) =>
  searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
  if (location) {
    var query = ""
    let pathUri_main = location.pathname.split("/property/rentals")

    var areaVal = ""
    var bedVal = 10
    var minpriceVal = ""
    var maxpriceVal = ""
    var pageVal = ""
    var sortVal = ""
    var sortVal_filt = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")
      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }
        if (pathUri[vi].indexOf("in-") >= 0) {
          // its area
          areaVal = pathUri[vi].replace("in-", "")
        }
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          // its sortby
          sortVal_filt = pathUri[vi].replace("sortby-", "")
          if(sortVal_filt === "price-desc") {
            sortVal = "price_DEV_desc"
          }
          if(sortVal_filt === "bedroom-desc") {
            sortVal = "bedroom_DEV_asc"
          }
        }
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          // its bedrooms
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }
        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          // its price
          let priceFilt1 = pathUri[vi].split("over-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }
        // page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  if (areaVal) {
    areaVal=areaVal.toLowerCase();
    if (areaVal == "west-yorkshire") query += `&query=`
    else query += `&query=` + areaVal
  }

  if (bedVal == 0) {
    query += `&range[bedroom][min]=` + 0
  } else if (bedVal < 10) {
    query += `&range[bedroom][min]=` + bedVal
  }

  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }

  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }

  if (pageVal)
    query += `&page=${pageVal}`

  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  return qs.parse(query)
}

const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  return (
    <div className="form form-filter">
        <div className="form-group form-group-location">
          <div className="custom-float">
  
          <label className="form-label">Location</label>
          <input type="text" className="form-control" placeholder="e.g West Yorkshire"
          id="search"
          name="search"
          type="search"
          onChange={event => refine(event.currentTarget.value)}
          value={currentRefinement}
          />
          </div>
        </div>
    </div>
  )
})

const Stats = ({ processingTimeMS, nbHits }) => {

  const searchParams = parseSearchUrl(`${SEARCH_TYPES.RENTALS}`, window.location.pathname);
  // console.log(searchParams);
  const h1 = propertyH1(searchParams);
  // console.log(h1);
  const areaName = searchParams.areas;

  return (
     <><PropertyIntro property_count={nbHits} h1={h1} search_type="to rent" /></>
  )
}

const NoStats = ({ processingTimeMS, nbHits }) => {
  return (
    <>
    {nbHits == 0 && 
      <Row>
        <Col lg={12} className="text-center mb-5">
          <div>No results have been found.</div>
        </Col>
      </Row>
    }
    </>
  )
}
const bedroomSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
    let bedroomvalselect = ""
  if (currentRefinement.max !== undefined) {
    bedroomvalselect = 0
  } else if (currentRefinement.min !== undefined) {
    bedroomvalselect = currentRefinement.min
  }
  return (
  <form>
  <div className="form-group">
  <div className="custom-float custom-float--select">
  <label className="form-label">Bedrooms</label>  
    <select
      name="bedroom"
      className="form-control"
      value={bedroomvalselect}
      onChange={event => {
        if (event.currentTarget.value === "") {
          refine(event.currentTarget.value)
        } else if (event.currentTarget.value === "0") {
          refine({
            max: event.currentTarget.value,
          })
        } else {
          refine({
            min: event.currentTarget.value,
          })
        }
      }}
    >
      <option value="">All Bedrooms</option>
      <option value="1" key="1+ Bedroom">
        1+ Bedroom
      </option>
      <option value="2" key="2+ Bedroom">
        2+ Bedroom
      </option>
      <option value="3" key="3+ Bedroom">
        3+ Bedroom
      </option>
      <option value="4" key="4+ Bedroom">
        4+ Bedroom
      </option>
      <option value="5" key="5+ Bedroom">
        5+ Bedroom
      </option>
    </select>
    </div>
    </div>
  </form>
)
}

const priceminRange = [
{
    value : "350",
    label : "£350 PCM"
},
{
    value : "450",
    label : "£450 PCM"
},
{
    value : "500",
    label : "£500 PCM"
},
{
    value : "550",
    label : "£550 PCM"
},
{
    value : "600",
    label : "£600 PCM"
},
{
    value : "650",
    label : "£650 PCM"
},
{
    value : "700",
    label : "£700 PCM"
},
{
    value : "800",
    label : "£800 PCM"
},
{
    value : "1000",
    label : "£1000 PCM"
},
{
    value : "1500",
    label : "£1500 PCM"
},
{
    value : "2000",
    label : "£2000 PCM"
}
]
const minpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let minPriceRange = priceminRange;
  if (currentRefinement.max !== undefined) {
    minPriceRange = priceminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max) )
  }
  return(
  <form>
  <div className="form-group">
  <div className="custom-float custom-float--select">
  <label className="form-label">Minimum Price</label>  
    <select
      name="minprice"
      className="form-control"
      value={currentRefinement.min || ""}
      onChange={event => {
        minpriceval = event.currentTarget.value
        if (event.currentTarget.value === "") {
          if (maxpriceval) {
            refine({
              max: maxpriceval,
            })
          } else refine(event.currentTarget.value)
        } else {
          refine({
            min: event.currentTarget.value,
            max: maxpriceval,
          })
        }
      }}
    >
      <option value="">Min Price</option>
      {minPriceRange.map((option, key) => {
        return(
          <option value={option.value} key={key}>
            {option.label}
          </option>
        )
      })}
    </select>
    </div>
    </div>
  </form>
)}

const pricemaxRange = [
{
    value : "350",
    label : "£350 PCM"
},
{
    value : "450",
    label : "£450 PCM"
},
{
    value : "500",
    label : "£500 PCM"
},
{
    value : "550",
    label : "£550 PCM"
},
{
    value : "600",
    label : "£600 PCM"
},
{
    value : "650",
    label : "£650 PCM"
},
{
    value : "700",
    label : "£700 PCM"
},
{
    value : "800",
    label : "£800 PCM"
},
{
    value : "1000",
    label : "£1000 PCM"
},
{
    value : "1500",
    label : "£1500 PCM"
},
{
    value : "2000",
    label : "£2000 PCM"
}
]
const maxpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let maxPriceRange = pricemaxRange;
  let maxpricedisable = ''
  if (currentRefinement.min !== undefined) {
    maxPriceRange = pricemaxRange.filter((x) => parseInt(x.value) > parseInt(currentRefinement.min) )
  }
  if (currentRefinement.min == "10000000") {
    maxpricedisable = 'disabled'
  }
  return(
  <form>
  <div className="form-group">
  <div className="custom-float custom-float--select">
  <label className="form-label">Maximum Price</label>  
    <select
      name="maxprice"
      className="form-control"
      disabled={maxpricedisable}
      value={currentRefinement.max || ""}
      onChange={event => {
        maxpriceval = event.currentTarget.value
        if (event.currentTarget.value === "") {
          if (minpriceval) {
            refine({
              min: minpriceval,
            })
          } else refine(event.currentTarget.value)
        } else {
          refine({
            min: minpriceval,
            max: event.currentTarget.value,
          })
        }
      }}
    >
      <option value="">Max Price</option>
      {maxPriceRange.map((option, key) => {
        return(
          <option value={option.value} key={key}>
            {option.label}
          </option>
        )
      })}
    </select>
    </div>
    </div>
  </form>
)}


const CustombedroomSelectbox = connectRange(bedroomSelectbox)
const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox)
const CustomminpriceSelectbox = connectRange(minpriceSelectbox)

const CustomStats = connectStats(Stats)

const CustomNoStats = connectStats(NoStats)

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)


class PropertiesSalesPage extends Component {
  state = {
    searchState: urlToSearchState(this.props.location),
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) })
    }
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState)
      this.debouncedSetState = setTimeout(() => {
        navigate(searchStateToUrl(this.props, searchState), searchState)
      }, updateAfter)
    this.setState({ searchState })
  }

    componentDidMount() {
  
if ( (window.location.href).indexOf('property\/rentals') ) {
$(window).scroll(function() {

// Infinite scroll pagination code below - START
//  if ( $("#mynextbut").length ) {
//    var top_of_element = $("#mynextbut").offset().top;
//    var bottom_of_element = $("#mynextbut").offset().top + $("#mynextbut").outerHeight();
//    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
//    var top_of_screen = $(window).scrollTop();
//
//    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
//      $("#mynextbut").trigger( "click" );
//       //window.scrollTo({ top: top_of_element-100, behavior: 'smooth' });
//        // the element is visible, do something
//    }
//  }
//  if ( $("#myprevbut").length ) {
//    var top_of_element = $("#myprevbut").offset().top;
//    var bottom_of_element = $("#myprevbut").offset().top + $("#myprevbut").outerHeight();
//    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
//    var top_of_screen = $(window).scrollTop();
//
//    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
//      $("#myprevbut").trigger( "click" );
//        // the element is visible, do something
//    }
//  }
// Infinite scroll pagination code below - END

});


// Infinite scroll pagination code below - START
//var existConditionbutt = setInterval(function() {
//  if ( $("#myprevbut").length ) {
//    clearInterval(existConditionbutt);
//    var top_of_element = $("#myprevbut").offset().top;
//    var bottom_of_element = $("#myprevbut").offset().top + $("#myprevbut").outerHeight();
//    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
//    var top_of_screen = $(window).scrollTop();
//
//    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
//      $("#myprevbut").trigger( "click" );
//    }
//  }
//}, 100);
// Infinite scroll pagination code below - END

if ( mycookies.get('myRentProp') !== "" ) {
var existCondition = setInterval(function() {
 if ($(`#myprop${mycookies.get('myRentProp')}`).length) {
    clearInterval(existCondition);
      $("body,html").animate(
      {
        scrollTop: $(`#myprop${mycookies.get('myRentProp')}`).offset().top-(isMobile ? 0 : 150)
      },
      500);
 }
}, 500);
}
}


  }


  render() {
  return (
    <Layout
      searchType={SEARCH_TYPES.RENTALS}
      searchState={this.state.searchState}
    >
      {/* {console.log("Inside rentals.js")} */}
      <Helmet 
        bodyAttributes={{
          class: 'search-results'
        }}
      />
      <MainContent>
        <Container>
          <Row className={classNames('mx-md-n4')}>
<InstantSearch indexName={index_name} searchClient={searchClient} searchState={this.state.searchState} onSearchStateChange={this.onSearchStateChange}
              createURL={createURL}
              routing="true"
>
          <CustomStats />
          <div className="right-panel">
          <div className="hidden">
            <RefinementList
                        attribute="search_type"
                        defaultRefinement={["lettings"]}
                      />
            </div>
            <Row>
              <Col xl={5} lg={3} md={3} className="form-140">
                <SearchBox />
              </Col>
              <Col xl={3} lg={3} md={3} className="form-140">
                <CustombedroomSelectbox attribute="bedroom" />
              </Col>
              <Col xl={2} lg={3} md={3} className="form-140">
                <CustomminpriceSelectbox attribute="price" />
              </Col>
              <Col xl={2} lg={3} md={3} className="form-140">
                <CustommaxpriceSelectbox attribute="price" />
              </Col>
            </Row>
            <br />
            <CustomInfiniteHits location="" />
            
          </div>
          <Configure hitsPerPage={PROPERTIES_PER_PAGE} />
        </InstantSearch>
          </Row>
        </Container>
      </MainContent>
    </Layout>
  );
}
}

export default PropertiesSalesPage;