import React, { useRef, useEffect } from "react";
import classNames from 'classnames';
import { Row, Col, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from "gatsby"
import $ from "jquery"

/**
 * Context API
 */
import { useGlobalState } from '@store/hooks';

/**
 * Assets
 */
import HeartIcon from '@static/svg/heart-icon2.svg';
import SortIcon from '@static/svg/sort-icon.svg';

/**
 * Components
 */
import Icon from '@components/icon/icon';
import Holder from '@components/holder/holder';

const popover = (
  <Popover id="popover-basic">
    <Popover.Content>
      Save to Favourites
    </Popover.Content>
  </Popover>
);

const PropertyIntro = (props, {search_type,className}) => {
  const target = useRef(null);
  const { services } = useGlobalState('modals');

  const modalOpen = (event) => {
    event.preventDefault();
    services.modalToggle({name: 'edit_search', active: true});
  }

  useEffect(() => {
    $(".filter-search").click(function() {
      $(".search-filter").toggleClass("active")
      $(".filter-search").toggleClass("active")
    })
  });

  return (
    <Row className={classNames(className)}>
      <Col lg={9} className="heading">
        <h4>{props.h1} ({props.property_count})</h4>
        <p>Discover a wide range of property {search_type} {props.h1} with Holroyds Estate Agents. For more info about buying property in West Yorkshire, please <Link to="/contact-west-yorkshire-estate-agents">contact Holroyds</Link></p>
      </Col>
      <Col lg={true}>
        <div className="options filter-search">
        <p className="filter-text"><span className="show-text">Show Filters</span> <span className="hide-text">Hide Filters</span></p>
          <div className="hidden">
          <Holder maxWidth="220px" mobile={false}>
            <Button className="primary-button" onClick={modalOpen}>Edit Search</Button>
          </Holder>
          <OverlayTrigger target={target.current} placement="top" overlay={popover}>
            <div className="icon-item" ref={target}>
              <Icon>
                <HeartIcon />
              </Icon>
            </div>
          </OverlayTrigger>
          </div>
          <div className="icon-item">
            <Icon>
              <SortIcon />
            </Icon>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default PropertyIntro;
